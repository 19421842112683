<template>
    <div class="layout-table">
        <div class="flex  jcsb">
            <div><slot></slot></div>
            <el-form :inline="true" :model="searchForm" class="layout-form-inline" v-if="searchFormItems.length">
                <el-form-item :label="item.label" v-for="(item, index) in searchFormItems" :key="index">
                    <el-input v-model="searchForm[item.prop]" :placeholder="item.placeholder" v-if="item.type == 'input'"></el-input>

                    <el-select v-model="searchForm[item.prop]" clearable :placeholder="item.placeholder" v-if="item.type == 'select'">
                        <el-option 
                            :label="option.label" 
                            :value="option.value" 
                            v-for="(option, optionIndex) in item.options" 
                            :key="optionIndex"/>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="SearchSubmit">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table :data="table.datas" v-loading="table.loading" @current-change="TableCurrentChange" style="width: 100%">
            <el-table-column 
                :label="item.label" 
                :width="item.width"
                v-for="(item, index) in columns" :key="index" >
                <template #default="scope">
                    <el-image 
                        style="width: 50px; height: 50px" 
                        :z-index="20220315"
                        fit="fill" 
                        :src="imageUrl + scope.row[item.prop]" 
                        v-if="item.type == 'image'"></el-image>
                        <!-- :preview-src-list="[imageUrl + scope.row[item.prop]]" -->

                    <div v-else-if="item.type === 'operate'">
                        <template v-for="(btnItem, btnIndex) in item.button" >
                            <el-button type="primary" size="small"
                            :key="btnIndex"
                            @click="OperateCallBack(scope, btnItem)"
                            v-if="btnItem.condition ? btnItem.condition(scope.row) : true">{{btnItem.label}}</el-button>
                        </template>
                    </div>
                    <div class="template-text" v-else>
                        <span v-if="item.render" v-html="item.render(scope.row[item.prop], scope)"></span>
                        <span v-else>{{scope.row[item.prop]}}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            v-model:currentPage="table.page"
            v-model:page-size="table.size"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="table.total"
            @size-change="PaginationSizeChange"
            @current-change="PaginationCurrentChange"
            v-if="pagination"
        >
        </el-pagination>
    </div>
</template>


<script>
import { reactive, ref, inject, onMounted } from 'vue'

export default {
    props: {
        columns: {
            type: Array,
            default () {
                return []
            }
        },
        searchFormItems: {
            type: Array,
            default () {
                return []
            }
        },
        url: {
            type: String,
            default: ''
        },
        pagination: {
            type: Boolean,
            default: true
        }
    },
    emits: ['operate-button-call-back', 'current-change'],
    setup (props, ctx) {
        const axios = inject('axios')
        const imageUrl = process.env.VUE_APP_BASE_IMAGE_URL
        let table = reactive({ datas: [], page: 1, size: 10, total: 0, loading: false })
        let searchForm = ref({})

        const methods = {
            PaginationSizeChange () {
                methods.GetTableData()
            },
            PaginationCurrentChange () {
                methods.GetTableData()
            },
            SearchSubmit () {
                table.page = 1;
                methods.GetTableData()
            },
            GetTableData () {
                table.loading = true
                let params = {...searchForm.value}

                if (props.pagination) {
                    params['page'] = table.page;
                    params['size'] = table.size;
                }

                axios.post(props.url, params).then(res => {
                    table.loading = false
                    if (!res.ret) {
                        if (!props.pagination) {
                            table.datas = res.data || []
                            return
                        }
                        table.datas = res.data.list || []
                        table.total = res.data.total
                    }
                })
            },
            OperateCallBack (scope, item) {
                ctx.emit('operate-button-call-back', {...scope, fun: item.dispose})
            },
            TableCurrentChange (params) {
                ctx.emit('current-change', params)
            }
        }

        onMounted(() => {
            methods.GetTableData()
        })

        return {
            table,
            imageUrl,
            searchForm,
            ...methods
        }
    }  
}
</script>


<style lang="scss" scoped>
.layout-table {
    .el-pagination {
        padding-top: 30px;
    }
}
.layout-form-inline{
    .el-input {
        width: 140px;
    }
    .el-select{
        width: 140px;
    }
}
</style>